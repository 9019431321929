import { useEffect, useState } from "react";

import { PlusCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Search from "antd/es/input/Search";
import { useNavigate, useSearchParams } from "react-router-dom";

import useUsers from "../../../api/hooks/useUsers";
import useAuth from "../../../hooks/useAuth";
import useWindowSize from "../../../hooks/useWindowSize";
import Loader from "../../Loader/Loader";
import UsersListTable from "./UsersListTable/UsersListTable";

function UsersList() {
  const navigate = useNavigate();
  const window = useWindowSize();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const { auth } = useAuth();
  const pageSize = auth?.rowsPerPage;
  // get search params from URl and format them
  // as expected from the API
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const { page } = params;
  const {
    data: users,
    status,
    refetch: getUsers,
  } = useUsers(
    {
      pageSize,
      startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
      search: searchQuery,
    },
    {
      enabled: pageSize > 0,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    getUsers();
  }, [debouncedSearchQuery, page, getUsers]);

  const onPageChange = (pagination) => {
    setSearchParams({ ...params, page: pagination.current });
  };

  const onSearch = (value) => {
    setSearchQuery(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const goToUserCreate = () => {
    navigate("create-user");
  };

  return (
    <div className="content-wrapper overflow-y-auto">
      <div className="users-list flex h-full flex-col">
        <div className="flex items-center mb-8">
          {window?.width > 992 && (
            <h1 className="page-title mb-0">Users list</h1>
          )}

          {users?.TotalCount > 0 && (
            <div className="ml-auto inline-flex items-center justify-between">
              <Search
                className="w-full md:w-fit mr-4"
                placeholder="Search Users"
                onSearch={onSearch}
                onChange={handleSearchChange}
                allowClear
                size="large"
              />
              <Button
                size="large"
                type="primary"
                className="ml-auto"
                onClick={goToUserCreate}
              >
                <PlusCircleOutlined />
                Create New User
              </Button>
            </div>
          )}
        </div>

        {status === "success" && users?.TotalCount === 0 && (
          <div className="flex flex-col items-center justify-center h-full">
            <span className="text-triple-white">
              <UserOutlined style={{ fontSize: "1000%" }} />
            </span>
            <span className="text-white text-opacity-80 text-center">
              There are no existing users
            </span>
            <Button
              size="large"
              type="primary"
              className="mt-2"
              onClick={goToUserCreate}
            >
              <PlusCircleOutlined />
              Create New User
            </Button>
          </div>
        )}

        {!pageSize && <Loader className="absolute-center" />}

        <UsersListTable
          data={users?.Result}
          status={status}
          totalCount={users?.TotalCount}
          onChange={onPageChange}
        />
      </div>
    </div>
  );
}

export default UsersList;
