import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const usePropertiesWithSystems = (
  options,
  key = "getPropertiesWithSystems",
) => {
  const axiosPrivate = useAxiosPrivate();

  const getPropertiesWithSystems = async () => {
    const propertiesWithSystems = await axiosPrivate({
      method: "GET",
      url: "/Systems/GetPropertiesWithSystems",
    }).then((response) => response.data);

    return propertiesWithSystems;
  };

  return useQuery([key], () => getPropertiesWithSystems(), {
    ...options,
  });
};

export default usePropertiesWithSystems;
