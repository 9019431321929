function DashboardIcon() {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.625 6.188c0-2.298.025-3.063 3.063-3.063 3.037 0 3.062.765 3.062 3.063 0 2.297.01 3.062-3.063 3.062-3.072 0-3.062-.765-3.062-3.063zM12.25 6.188c0-2.298.025-3.063 3.063-3.063 3.037 0 3.062.765 3.062 3.063 0 2.297.01 3.062-3.063 3.062-3.072 0-3.062-.765-3.062-3.063zM2.625 15.813c0-2.298.025-3.063 3.063-3.063 3.037 0 3.062.765 3.062 3.063 0 2.297.01 3.062-3.063 3.062-3.072 0-3.062-.765-3.062-3.063zM12.25 15.813c0-2.298.025-3.063 3.063-3.063 3.037 0 3.062.765 3.062 3.063 0 2.297.01 3.062-3.063 3.062-3.072 0-3.062-.765-3.062-3.063z"
        stroke="#E1E0E3"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="blue-both-colors"
      />
    </svg>
  );
}

export default DashboardIcon;
