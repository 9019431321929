import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useUpdatePropertyBasics(propertyId) {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (request) => {
      return axiosPrivate({
        method: "PUT",
        url: `Property/${propertyId}/Preferences`,
        data: request,
      });
    },
  });
}

export default useUpdatePropertyBasics;
