import { useState } from "react";

import { EditOutlined } from "@ant-design/icons";
import { Row, Col, Divider, Button, Modal } from "antd";
import PropTypes from "prop-types";

import logo from "../../../assets/img/property-overview-PIC-01.png";
import useWindowSize from "../../../hooks/useWindowSize";
import PropertyPreferences from "./PropertyPreferences";

function PropertyManagementCard({
  className,
  property,
  refetchPropertyBasics,
}) {
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useState(false);
  const window = useWindowSize();

  const onEditButtonClick = () => {
    setIsPropertyModalOpen(true);
  };

  const onCancelButtonClick = () => {
    setIsPropertyModalOpen(false);
    refetchPropertyBasics();
  };

  return (
    <div
      role="none"
      className={`property border-triple-blue h-full ${className}`}
    >
      <Row className="h-full">
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <div
            style={{
              backgroundImage: `url(${
                property.ImageUrl ? property.ImageUrl : logo
              })`,
              backgroundSize: property?.ImageUrl ? "cover" : "contain",
            }}
            className={`property-image h-44 ${
              window?.width < 991 ? "property-image--blured-bottom" : ""
            }`}
          >
            &nbsp;
          </div>
        </Col>

        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <div className={`property-details ${className} `}>
            <h3 className="property-details-title">{property?.Name}</h3>
            <div className="property-details-address text-15 text-secondary-white">
              {property?.Address}
            </div>
          </div>
        </Col>

        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 1 }}
          className="flex items-center justify-center lg:m-0"
        >
          <Divider
            type={window?.width > 992 ? "vertical" : "horizontal"}
            className="lg:h-4/5 m-0"
          />
        </Col>

        <Col
          xs={{ span: 24 }}
          lg={{ span: 11 }}
          className="flex justify-end items-center"
        >
          <Button
            type="primary"
            onClick={onEditButtonClick}
            size="large"
            icon={<EditOutlined />}
            className="mr-2 my-2"
          >
            EDIT
          </Button>
        </Col>
      </Row>
      <Modal
        className="property-management-modal"
        maskClosable={false}
        footer={null}
        open={isPropertyModalOpen}
        keyboard={false}
        onCancel={onCancelButtonClick}
      >
        <PropertyPreferences propertyId={property?.Id} />
      </Modal>
    </div>
  );
}

PropertyManagementCard.propTypes = {
  className: PropTypes.string,
  property: PropTypes.object,
  refetchPropertyBasics: PropTypes.func,
};

PropertyManagementCard.defaultProps = {
  className: "",
  property: {},
  refetchPropertyBasics: () => {},
};

export default PropertyManagementCard;
