import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useLocation, Navigate } from "react-router-dom";

import useUser from "../api/hooks/useUser";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = { children: null };

// protect private app routes
function RequireAdmin({ children }) {
  const location = useLocation();
  const [userRoles, setUserRoles] = useState();
  const { refetch: getLoggedUser } = useUser();

  useEffect(() => {
    getLoggedUser().then((res) => {
      setUserRoles(() => res.data.Roles);
    });
  }, [getLoggedUser]);

  if (userRoles) {
    if (userRoles?.includes("MonitorStationAdmin")) {
      return children;
    }
    return <Navigate to="/forbidden" state={{ from: location }} replace />;
  }
}

RequireAdmin.propTypes = propTypes;
RequireAdmin.defaultProps = defaultProps;

export default RequireAdmin;
