import { useState } from "react";

import {
  FileDoneOutlined,
  BarChartOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Menu, Modal } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import AnalyticsIcon from "../../assets/icons/sidebar/AnalyticsIcon";
import DashboardIcon from "../../assets/icons/sidebar/DashboardIcon";
import EventsIcon from "../../assets/icons/sidebar/EventsIcon";
import FlowConfigurationIcon from "../../assets/icons/sidebar/FlowConfigurationIcon";
import LogoutIcon from "../../assets/icons/sidebar/LogoutIcon";
import ProfileIcon from "../../assets/icons/sidebar/ProfileIcon";
import PropertyOverviewIcon from "../../assets/icons/sidebar/PropertyOverviewIcon";
import ScheduleIcon from "../../assets/icons/sidebar/ScheduleIcon";
import SystemPreferencesIcon from "../../assets/icons/sidebar/SystemPreferencesIcon";
import UsersIcon from "../../assets/icons/sidebar/UsersIcon";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import useWindowSize from "../../hooks/useWindowSize";
import "./Sidebar.scss";
import Profile from "../Profile/Profile";

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = { className: "" };

// used to generate the items for the sidebar [ant design specific]
// eslint-disable-next-line no-unused-vars
function getItem(label, key, icon, children, type, user) {
  if (key === "preferences") {
    // if (user?.Roles?.includes("MonitorStationAdmin")) {
    //   return {
    //     key,
    //     icon,
    //     children,
    //     label,
    //     type,
    //   };
    // }

    return {
      key: "forbidden",
      disabled: true,
    };
  }

  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

function Sidebar({ className }) {
  const { auth } = useAuth();
  const window = useWindowSize();
  const logout = useLogout();

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const onProfileClick = () => {
    setIsProfileModalOpen(true);
  };

  const items = [
    getItem(
      <NavLink to="/" className="nav-link" end>
        <DashboardIcon />
        {t("sidebar.dashboard")}
      </NavLink>,
      "dashboard",
    ),
    getItem(
      <NavLink to="/property-overview" className="nav-link">
        <PropertyOverviewIcon />
        {t("sidebar.property_overview")}
      </NavLink>,
      "property-overview",
    ),
    getItem(
      <div className="px-2">{t("sidebar.statistics.statistics")}</div>,
      "statistics",
      <AnalyticsIcon />,
      [
        getItem(
          <NavLink to="/statistics/charts" className="nav-link">
            <BarChartOutlined className="pr-2" style={{ fontSize: "140%" }} />
            {t("sidebar.statistics.charts")}
          </NavLink>,
          "statistics.charts",
        ),
        getItem(
          <NavLink to="/statistics/reports" className="nav-link">
            <FileDoneOutlined className="pr-2" style={{ fontSize: "130%" }} />
            {t("sidebar.statistics.reports")}
          </NavLink>,
          "statistics.reports",
        ),
      ],
    ),
    getItem(
      <NavLink to="/events" className="nav-link">
        <EventsIcon />
        {t("sidebar.events")}
      </NavLink>,
      "events",
    ),
    getItem(
      t("sidebar.settings.settings"),
      "settings",
      <SettingOutlined style={{ fontSize: "130%" }} />,
      [
        getItem(
          <NavLink to="/settings/flow-configuration" className="nav-link">
            <FlowConfigurationIcon />
            {t("sidebar.settings.flow_configuration")}
          </NavLink>,
          "settings.flow-configuration",
        ),
        getItem(
          <NavLink to="/settings/schedule" className="nav-link">
            <ScheduleIcon />
            {t("sidebar.settings.schedule")}
          </NavLink>,
          "settings.schedule",
        ),
      ],
    ),
    getItem(
      t("sidebar.preferences.preferences"),
      "preferences",
      <span className="">
        <SystemPreferencesIcon />
      </span>,
      [
        getItem(
          <NavLink to="/users" className="nav-link hidden">
            <UsersIcon />
            {t("sidebar.preferences.users")}
          </NavLink>,
          "users",
        ),
        getItem(
          <NavLink to="/property-management" className="nav-link">
            <PropertyOverviewIcon />
            {t("sidebar.preferences.property_management")}
          </NavLink>,
          "property-management",
        ),
      ],
      null,
      auth.user,
    ),
    getItem(
      <Button type="link" onClick={onProfileClick} className="nav-link">
        <ProfileIcon />
        Profile
      </Button>,
      "profile",
    ),
    getItem(
      <NavLink to="/login" className="nav-link" onClick={logout}>
        <LogoutIcon />
        Logout
      </NavLink>,
      "logout",
    ),
  ];

  return (
    <>
      <Menu
        className={`sidebar bg-triple-sidebar text-triple-white ${className}`}
        items={window?.width <= 992 ? items : items.slice(0, -2)}
        mode="inline"
      />
      <Modal
        className="profile-modal"
        maskClosable={false}
        okButtonProps={{
          size: "small",
        }}
        centered
        footer={null}
        okText="Save"
        open={isProfileModalOpen}
        keyboard={false}
        onCancel={() => setIsProfileModalOpen(false)}
      >
        <Profile />
      </Modal>
    </>
  );
}

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default Sidebar;
