/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { useEffect, useRef } from "react";

import Cookies from "js-cookie";

import { axiosPrivate } from "../api/axios";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";

// hook to attach interceptors to the axios instance for making authorized requests within the app
const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  const isRefreshing = useRef();
  isRefreshing.current = false;
  const failedQueue = useRef();
  failedQueue.current = [];

  const processQueue = (error, token = null) => {
    failedQueue.current.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    failedQueue.current = [];
  };

  useEffect(() => {
    // interceptor to attach the access_token BEFORE making the request
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers.Authorization) {
          config.headers.Authorization = `Bearer ${auth?.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    // interceptor to refresh the access_token if expired and re-send the request
    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error?.config;
        if (error?.response?.status === 401 && !originalRequest?._retry) {
          if (isRefreshing.current) {
            return new Promise((resolve, reject) => {
              failedQueue.current.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers.Authorization = `Bearer ${token}`;
                return axiosPrivate(originalRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }

          originalRequest._retry = true;
          isRefreshing.current = true;

          return new Promise(async (resolve, reject) => {
            try {
              const response = await refresh();
              originalRequest.headers.Authorization = `Bearer ${response.access_token}`;
              Cookies.remove("refreshToken");
              const expiration = new Date();
              expiration.setHours(expiration.getHours() + 2);
              Cookies.set("refreshToken", response.refresh_token, {
                expires: expiration,
              });
              processQueue(null, response.access_token);
              resolve(axiosPrivate(originalRequest));
            } catch (error) {
              processQueue(error, null);
              reject(error);
            } finally {
              isRefreshing.current = false;
            }
          });
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
