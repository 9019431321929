import PropTypes from "prop-types";

function WaterDropIcon({ className = "" }) {
  return (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10 8.87583C10 11.6369 8.26156 13.375 5.5 13.375C2.73844 13.375 1 11.6369 1 8.87583C1 5.91294 4.22594 1.91305 5.21531 0.756072C5.25052 0.714966 5.2942 0.681966 5.34337 0.659341C5.39254 0.636715 5.44603 0.625 5.50016 0.625C5.55428 0.625 5.60777 0.636715 5.65694 0.659341C5.70611 0.681966 5.7498 0.714966 5.785 0.756072C6.77406 1.91305 10 5.91294 10 8.87583Z"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M8.125 9.25049C8.125 9.84723 7.88795 10.4195 7.46599 10.8415C7.04403 11.2634 6.47174 11.5005 5.875 11.5005"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

WaterDropIcon.propTypes = {
  className: PropTypes.string,
};

WaterDropIcon.defaultProps = {
  className: "",
};

export default WaterDropIcon;
