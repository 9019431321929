/* eslint-disable no-param-reassign */
import dayjs from "dayjs";

const PropertyPreferencesMapper = (destination, source) => {
  destination.current.Name = source.Name;
  destination.current.Address = source.Address;
  destination.current.ImageUrl = source.ImageUrl;
  destination.current.NumberOfOccupants =
    source.PropertyPreferences.NumberOfOccupants;
  destination.current.CostOfWater = source.PropertyPreferences.CostOfWater;
  destination.current.Currency = source.PropertyPreferences.Currency;
  destination.current.WorkingDaysEnabled =
    source.PropertyPreferences.WorkingDaysEnabled;
  destination.current.WorkingDaysStartDay =
    source.PropertyPreferences.WorkingDaysStartDayOfWeek;
  destination.current.WorkingDaysEndDay =
    source.PropertyPreferences.WorkingDaysEndDayOfWeek;
  destination.current.WorkingDaysStartTime = dayjs(
    new Date(
      source.PropertyPreferences.WorkingDaysStartTime,
    ).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
    "HH:mm",
  );
  destination.current.WorkingDaysEndTime = dayjs(
    new Date(source.PropertyPreferences.WorkingDaysEndTime).toLocaleTimeString(
      [],
      {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      },
    ),
    "HH:mm",
  );

  return destination;
};

export default PropertyPreferencesMapper;
