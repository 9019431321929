/* eslint-disable no-unused-vars */
import { useState } from "react";

import { Avatar, Button, Divider, Modal } from "antd";
import { NavLink } from "react-router-dom";

import LogoutIcon from "../../../assets/icons/sidebar/LogoutIcon";
import ProfileIcon from "../../../assets/icons/sidebar/ProfileIcon";
import useAuth from "../../../hooks/useAuth";
import useLogout from "../../../hooks/useLogout";
import Profile from "../../Profile/Profile";

import "./UserDetails.scss";

const propTypes = {};

const defaultProps = {};

function UserDetails() {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const logout = useLogout();
  const { auth } = useAuth();

  const onAvatarClick = () => {
    setIsProfileModalOpen(true);
  };

  return (
    <div className="flex items-center justify-center">
      <Divider type="vertical" style={{ height: "2em" }} />
      <Avatar
        onClick={onAvatarClick}
        title="Profile"
        icon={!auth?.user?.ImageUrl ? <ProfileIcon /> : null}
        src={auth?.user?.ImageUrl ? auth?.user?.ImageUrl : null}
        className="flex items-center justify-center cursor-pointer bg-[#51566e] text-triple-background mx-2"
      />
      <div className="flex flex-col">
        <span data-testid="user-firstName">{auth?.user?.FirstName}</span>
        <span className="text-12" data-testid="user-role">
          {auth?.user?.Roles[0]}
        </span>
      </div>
      <Modal
        className="profile-modal"
        maskClosable={false}
        footer={null}
        open={isProfileModalOpen}
        keyboard={false}
        onCancel={() => setIsProfileModalOpen(false)}
        width={550}
      >
        <Profile />
      </Modal>
      <Divider type="vertical" style={{ height: "2em" }} />
      <NavLink
        to="/login"
        className="flex items-center ml-2"
        onClick={logout}
        title="Logout"
        data-testid="logout-btn"
      >
        <LogoutIcon />
      </NavLink>
    </div>
  );
}

UserDetails.propTypes = propTypes;
UserDetails.defaultProps = defaultProps;

export default UserDetails;
