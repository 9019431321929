import { useState } from "react";

import { Button, Card, Checkbox, Col, Form, Row, Select } from "antd";
import PropTypes from "prop-types";

import "./Permission.scss";

const propertyTypes = {
  data: PropTypes.array,
  formRef: PropTypes.object,
  systemsPermissionRef: PropTypes.object,
};

const defaultPropertyTypes = {
  data: [],
  formRef: {},
  systemsPermissionRef: PropTypes.object,
};

function Permission({ data, formRef, systemsPermissionRef }) {
  const [property, setProperty] = useState();
  const [floor, setFloor] = useState();
  const [activeProperty, setActiveProperty] = useState("");
  const [activeFloor, setActiveFloor] = useState("");

  const toggleActiveProperty = (event) => {
    const { id } = event.currentTarget;
    setActiveProperty(id);
  };

  const isActiveProperty = (id) => {
    return activeProperty === id;
  };

  const toggleActiveFloor = (event) => {
    const { id } = event.currentTarget;
    setActiveFloor(id);
  };

  const isActiveFloor = (id) => {
    return activeFloor === id;
  };

  const handleOnFloorSelect = (floor) => {
    setFloor(floor);
  };

  const handleOnPropertyChange = () => {
    formRef.setFieldValue("ApplyToAll", false);
  };

  return (
    <div className="permissions my-2">
      <div className="flex flex-col md:flex-row">
        <h1 className="page-title">Permission</h1>
        <div className="md:ml-auto inline-flex">
          <Form.Item className="mb-4" name="ApplyToAll" valuePropName="checked">
            <Checkbox className="mx-2">Apply to All</Checkbox>
          </Form.Item>
          <Form.Item className="mb-4 w-4/6 md:w-32" name="Permission">
            <Select
              size="small"
              options={[
                { value: "FullAccess", label: "Full" },
                { value: "ReadOnly", label: "Read Only" },
                { value: "Forbidden", label: "Forbidden" },
              ]}
            />
          </Form.Item>
        </div>
      </div>
      <div className="system-permissions my-2">
        <Card headStyle={{ padding: 0 }} bodyStyle={{ padding: 0 }}>
          <Card.Grid
            style={{ width: "20%", padding: 5 }}
            hoverable={false}
            className="text-ellipsis overflow-hidden"
          >
            <h3 className="property-col-title">Property</h3>
            {data.map((object) => (
              <Button
                type="link"
                block
                size="large"
                key={object.Id}
                id={object.Name}
                style={{ paddingLeft: 0 }}
                className={
                  isActiveProperty(`${object.Name}`) ? "active-button" : ""
                }
                onClick={(event) => {
                  setProperty(object);
                  setFloor(null);
                  setActiveFloor(null);
                  toggleActiveProperty(event);
                  handleOnPropertyChange(object);
                }}
              >
                <span className="float-left text-10 md:text-14">
                  {object.Name}
                </span>
              </Button>
            ))}
          </Card.Grid>
          <Card.Grid
            style={{ width: "20%", padding: 5 }}
            hoverable={false}
            className="text-ellipsis overflow-hidden"
          >
            <h3 className="floor-col-title">Floor</h3>
            {property?.Floors?.map((floor) => (
              <Button
                type="link"
                block
                size="large"
                key={floor.Id}
                id={floor.Name}
                style={{ paddingLeft: 0 }}
                className={
                  isActiveFloor(`${floor.Name}`) ? "active-button" : ""
                }
                onClick={(event) => {
                  handleOnFloorSelect(floor);
                  toggleActiveFloor(event);
                }}
              >
                <span className="float-left text-10 md:text-14">
                  {floor.Name}
                </span>
              </Button>
            ))}
          </Card.Grid>

          <Card.Grid style={{ width: "60%", padding: 5 }} hoverable={false}>
            <div className="flex justify-between mb-2">
              <h3 className="system-name-title">System Name</h3>
              <h3 className="system-permission-title">Permission</h3>
              <div />
            </div>
            {floor &&
              floor.Systems?.map((system) => {
                const systemPermission = systemsPermissionRef.current?.find(
                  (x) => x.SystemUniqueId === system.UniqueId,
                );

                if (!systemPermission) {
                  systemsPermissionRef.current.push({
                    SystemUniqueId: system.UniqueId,
                    PermissionType: "ReadOnly",
                  });
                }
                return (
                  <Row gutter={10} key={system.UniqueId}>
                    <Col md={12} xs={12}>
                      <span className="text-10 md:text-14">{system.Name}</span>
                    </Col>

                    <Col md={12} xs={12}>
                      <Select
                        className="w-full my-1"
                        size="small"
                        defaultValue={
                          systemPermission?.PermissionType ?? "ReadOnly"
                        }
                        onSelect={(value) => {
                          const permission = systemsPermissionRef.current.find(
                            (x) => x.SystemUniqueId === system.UniqueId,
                          );
                          permission.PermissionType = value;
                        }}
                        options={[
                          { value: "FullAccess", label: "Full" },
                          { value: "ReadOnly", label: "Read Only" },
                          { value: "Forbidden", label: "Forbidden" },
                        ]}
                      />
                    </Col>
                  </Row>
                );
              })}
          </Card.Grid>
        </Card>
      </div>
    </div>
  );
}

Permission.propTypes = propertyTypes;
Permission.defaultProps = defaultPropertyTypes;

export default Permission;
