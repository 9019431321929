import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useUsers = (params, options, key = "getUsers") => {
  const axiosPrivate = useAxiosPrivate();
  const getUsersList = async () => {
    const usersList = await axiosPrivate({
      method: "GET",
      url: "/Users/MonitorStationOperators",
      params,
    }).then((response) => response.data);
    return usersList;
  };

  return useQuery([key, params], () => getUsersList(params), {
    ...options,
  });
};

export default useUsers;
