import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useUser = () => {
  const axiosPrivate = useAxiosPrivate();

  const getLoggedUser = async () => {
    const loggedUser = await axiosPrivate({
      method: "GET",
      url: "/User",
    }).then((res) => res.data);

    return loggedUser;
  };

  return useQuery(["getLoggedUser"], getLoggedUser, {
    enabled: false,
    refetchOnMount: false,
    retry: false,
    staleTime: "Infinity",
  });
};

export default useUser;
