import { useState, useEffect, useCallback } from "react";

import { Col, Row } from "antd";
import dayjs from "dayjs";
import { Outlet } from "react-router-dom";

import useStatisticsAnnualWaterConsumptionV2 from "../../../api/hooks/useStatisticsAnnualWaterConsumptionV2";
import useStatisticsBiennialWaterConsumptionV2 from "../../../api/hooks/useStatisticsBiennialWaterConsumptionV2";
import DateConstants from "../../../constants/DateConstants";
import { ConvertWater } from "../../../helpers/waterHelper";
import useProfileSettings from "../../../hooks/useProfileSettings";
import Loader from "../../Loader/Loader";
import StatisticsCalendar from "../Calendar/StatisticsCalendar";
import StatisticsConsumptionCards from "../ConsumptionCards/StatisticsConsumptionCards";
import StatisticsFilters from "../Filters/StatisticsFilters";
import StatisticsPropertyOverview from "../PropertyOverview/StatisticsPropertyOverview";
import StatisticsChart from "./StatisticsCharts";

import "./Charts.scss";

function Charts() {
  const dateTimeNow = {
    MM: DateConstants.months[dayjs().$M],
    m: dayjs().$M,
    year: dayjs().$y,
    d: dayjs().$D,
  };

  // States
  const [property, setPropertyId] = useState(null);
  const [floorId, setFloorId] = useState(null);
  const [systemId, setSystemId] = useState(null);
  const [date, setDate] = useState(dateTimeNow);
  const [loading, setLoading] = useState(false);
  const [
    showPreviousWaterConsumptionData,
    setShowPreviousWaterConsumptionData,
  ] = useState(false);
  const [waterConsumptionData, setWaterConsumptionData] = useState();

  // Callbacks
  const handleOnChangeFilterCallback = (property, floorId, systemId) => {
    setPropertyId(property);
    setFloorId(floorId);
    setSystemId(systemId);
  };

  const handleTogglePreviousWaterConsumptionData = useCallback(
    () => setShowPreviousWaterConsumptionData((previousShow) => !previousShow),
    [],
  );

  // Fetching water consumption data
  const annualWaterConsumption = useStatisticsAnnualWaterConsumptionV2(
    date && date.year ? date.year : new Date().getFullYear(),
    {
      propertyId: property?.PropertyId,
      floorId,
      systemId,
    },
    { enabled: false, cacheTime: 0 },
  );

  const biennialWaterConsumption = useStatisticsBiennialWaterConsumptionV2(
    date && date.year ? date.year : new Date().getFullYear(),
    {
      propertyId: property?.PropertyId,
      floorId,
      systemId,
    },
    { enabled: false, cacheTime: 0 },
  );

  const { userSettings } = useProfileSettings();

  useEffect(() => {
    if (date) {
      setLoading(true);
      const fetchData = showPreviousWaterConsumptionData
        ? biennialWaterConsumption.refetch
        : annualWaterConsumption.refetch;

      fetchData(date.year - 1, {
        propertyId: property?.PropertyId,
        floorId,
        systemId,
      })
        .then((response) => {
          const { waterUnit } = userSettings;

          let responseDataArray = [];
          if (response && response.data && Array.isArray(response.data)) {
            responseDataArray = response.data;
          } else {
            const emptyPreviousYearData = {
              Year: response.data.Year - 1,
              TotalWaterConsumption: 0,
              StatisticsData: [],
            };
            const thisYearData = response.data;
            responseDataArray = [emptyPreviousYearData, thisYearData];
          }

          const mappedData = responseDataArray.map((yearDetails) => ({
            Year: yearDetails.Year,
            TotalWaterConsumption: ConvertWater(
              waterUnit,
              yearDetails.TotalWaterConsumption,
            ),
            StatisticsData: yearDetails.StatisticsData.map((s) => ({
              Month: s.Month,
              MonthAsInt: s.MonthAsInt,
              WaterConsumption: ConvertWater(
                waterUnit,
                s.WaterConsumption,
              ).toFixed(2),
              Offline: s.Offline,
              Check: s.Check,
              Leak: s.Leak,
              Alert: s.Alert,
              HasOffline: s.HasOffline,
              HasCheck: s.HasCheck,
              HasLeak: s.HasLeak,
            })),
          }));

          setWaterConsumptionData(mappedData);
        })
        .finally(() => setLoading(false));
    }
  }, [
    property,
    floorId,
    systemId,
    date,
    annualWaterConsumption.refetch,
    biennialWaterConsumption.refetch,
    userSettings,
    showPreviousWaterConsumptionData,
  ]);

  return (
    <div className="statistics">
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={9} xl={7} xxl={6}>
          <StatisticsPropertyOverview propertyOverview={property} />
          <StatisticsFilters
            handleOnChangeFilterCallback={handleOnChangeFilterCallback}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={15}
          xl={17}
          xxl={18}
          className="content-end"
        >
          <StatisticsConsumptionCards
            waterConsumptionTotal={
              waterConsumptionData?.length > 0 && waterConsumptionData[1]
                ? waterConsumptionData[1].TotalWaterConsumption
                : 0
            }
            showPreviousWaterConsumptionData={showPreviousWaterConsumptionData}
            loading={loading}
            onToggle={handleTogglePreviousWaterConsumptionData}
          />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={9} xl={7} xxl={6}>
          <StatisticsCalendar
            setDate={setDate}
            date={date}
            property={property}
            floor={floorId}
            systemId={systemId}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={15} xl={17} xxl={18}>
          {(biennialWaterConsumption.status === "success" ||
            annualWaterConsumption.status === "success") && (
            <StatisticsChart
              statisticsFilters={{
                propertyId: property?.PropertyId,
                floorId,
                systemId,
              }}
              waterConsumptionData={
                waterConsumptionData && waterConsumptionData[1]
                  ? waterConsumptionData[1]
                  : {}
              }
              previousWaterConsumptionData={
                waterConsumptionData && waterConsumptionData[0]
                  ? waterConsumptionData[0]
                  : {}
              }
              showPreviousWaterConsumptionData={
                showPreviousWaterConsumptionData
              }
              setDate={setDate}
              date={date}
            />
          )}
          {biennialWaterConsumption.status === "loading" &&
            annualWaterConsumption.status === "loading" && (
              <div className="flex justify-center mt-10">
                <Loader />
              </div>
            )}
        </Col>
      </Row>
      <Outlet />
    </div>
  );
}

export default Charts;
