function UsersIcon() {
  return (
    <svg
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.16 14.34"
    >
      <path
        className="blue-both-colors"
        fill="#E1E0E3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        d="m15.73,7.66h-1.31c1.25-.66,2.11-1.97,2.11-3.48,0-2.17-1.76-3.93-3.93-3.93s-3.93,1.76-3.93,3.93c0,1.51.85,2.82,2.11,3.48h-1.31c-2.77,0-5.02,2.25-5.02,5.02v1.41h16.32v-1.41c0-2.77-2.25-5.02-5.02-5.02Zm-3.14-5.85c1.31,0,2.37,1.06,2.37,2.37s-1.06,2.37-2.37,2.37-2.37-1.06-2.37-2.37,1.06-2.37,2.37-2.37Zm-6.6,10.72c.08-1.84,1.6-3.31,3.46-3.31h6.28c1.86,0,3.38,1.47,3.46,3.31H6Z"
      />
      <path
        className="blue-both-colors"
        fill="#E1E0E3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        d="m20.9,7.67h-1.05c1-.53,1.68-1.58,1.68-2.78,0-1.73-1.41-3.14-3.14-3.14s-3.14,1.41-3.14,3.14c0,1.21.68,2.26,1.68,2.78h-3.23s2.18,0,2.18,0v1.25s-2.18,0-2.18,0h7.2c1.48,0,2.7,1.18,2.76,2.65h-4.28v1.25h5.53v-1.13c0-2.21-1.8-4.01-4.01-4.01Zm-2.51-.89c-1.05,0-1.9-.85-1.9-1.9s.85-1.9,1.9-1.9,1.9.85,1.9,1.9-.85,1.9-1.9,1.9Z"
      />
      <path
        className="blue-both-colors"
        fill="#E1E0E3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        d="m.24,11.68v1.13s5.46,0,5.46,0v-1.25s-4.21,0-4.21,0c.06-1.47,1.28-2.65,2.76-2.65h6.75s-1.73,0-1.73,0v-1.25s1.73,0,1.73,0h-2.78c1-.53,1.68-1.58,1.68-2.78,0-1.73-1.41-3.14-3.14-3.14s-3.14,1.41-3.14,3.14c0,1.21.68,2.26,1.68,2.78h-1.05c-2.21,0-4.01,1.8-4.01,4.01Zm4.63-6.8c0-1.05.85-1.9,1.9-1.9s1.9.85,1.9,1.9-.85,1.9-1.9,1.9-1.9-.85-1.9-1.9Z"
      />
    </svg>
  );
}

export default UsersIcon;
