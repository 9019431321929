import PropTypes from "prop-types";

import CustomTable from "../../../CustomTable/CustomTable";
import "./UsersListTable.scss";

const propertyTypes = {
  data: PropTypes.array,
  status: PropTypes.string,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  onChange: PropTypes.func,
};

const defaultPropertyTypes = {
  data: [],
  status: "loading",
  pageSize: 3,
  totalCount: 0,
  onChange: () => {},
};

function UsersListTable({ data, status, pageSize, totalCount, onChange }) {
  const options = [
    { value: "FullAccess", label: "Full" },
    { value: "Read-Only", label: "Read Only" },
    { value: "Forbidden", label: "Forbidden" },
  ];
  const tableColumns = [
    {
      title: <span id="username-col">User Name</span>,
      key: "username",
      render: (object) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {object?.Username}
          </span>
        );
      },
      width: 250,
    },
    {
      title: "Email",
      key: "user_email",
      render: (object) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {object?.Email}
          </span>
        );
      },
      width: 350,
    },
    {
      title: "Phone",
      key: "user_phone",
      render: (object) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {object?.PhoneNumber}
          </span>
        );
      },
      width: 250,
    },
    {
      title: "Permission",
      key: "user_permission",
      render: (object) => {
        const permission = options.find(
          (option) => option.value === object.Permission,
        );
        return (
          <span className="text-white text-opacity-80 font-light">
            {permission ? permission.label : object.Permission}
          </span>
        );
      },
      width: 200,
    },
    // {
    //   title: null,
    //   key: "actions",
    //   render: (object) => {
    //     return (
    //       <Button
    //         type="link"
    //         size="small"
    //         onClick={() => navigate(`edit-user/${object?.Id}`)}
    //       >
    //         <EditOutlined />
    //         Edit
    //       </Button>
    //     );
    //   },
    //   width: 100,
    // },
  ];

  return (
    <CustomTable
      className={`${!pageSize ? "opacity-0" : "opacity-100"}`}
      data={data}
      status={status}
      autoRows
      noPagination={!(totalCount > pageSize) || status === "loading"}
      tableColumns={tableColumns}
      totalCount={totalCount}
      onChange={onChange}
      // onRowClick={(_, object) => {
      //   navigate(`edit-user/${object?.Id}`);
      // }}
    />
  );
}

UsersListTable.propTypes = propertyTypes;
UsersListTable.defaultProps = defaultPropertyTypes;

export default UsersListTable;
