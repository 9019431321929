import { useEffect, useRef, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Form, Input, Select, TimePicker, notification } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import useAttachments from "../../../api/hooks/useAttachments";
import usePropertyPreferences from "../../../api/hooks/usePropertyPreferences";
import useUpdatePropertyPreferences from "../../../api/hooks/useUpdatePropertyPreferences";
// import CurrencyOptions from "../../../constants/CurrencyOptions";
import DateConstants from "../../../constants/DateConstants";
import RegularExpressions from "../../../constants/RegExpressions";
import ImageUploader from "../../ImageUploader/ImageUploader";
import SaveChanges from "../../Shared/SaveChangesButton";
import Toggle from "../../Shared/Toggle";
import "./PropertyPreferences.scss";
import PropertyPreferencesMapper from "./PropertyPreferencesMapper";

function PropertyPreferences({ propertyId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveChanges] = useState(false);
  const [showWorkingDaysRoutine, setShowWorkingDaysRoutine] = useState(false);
  const [propertyPreferencesForm] = Form.useForm();
  const [fileListArr, setFileListArr] = useState([]);
  const initialFormValues = useRef({});

  const { mutate: updatePropertyPreferences } =
    useUpdatePropertyPreferences(propertyId);
  const { mutate: uploadAttachment } = useAttachments();

  const { refetch: getPropertyPreferences } = usePropertyPreferences(
    propertyId,
    { enabled: false },
  );

  useEffect(() => {
    getPropertyPreferences(propertyId).then((response) => {
      const initFormValues = PropertyPreferencesMapper(
        initialFormValues,
        response.data,
      );

      propertyPreferencesForm.setFieldsValue(initFormValues.current);

      setFileListArr(
        initFormValues.current?.ImageUrl
          ? [{ url: initFormValues.current?.ImageUrl }]
          : [],
      );

      if (initialFormValues.current.WorkingDaysEnabled) {
        setShowWorkingDaysRoutine(true);
      }
    });
  }, [getPropertyPreferences, propertyId, propertyPreferencesForm]);

  const clearFormFieldError = (fieldName) => {
    propertyPreferencesForm.setFields([
      {
        name: fieldName,
        errors: [],
      },
    ]);
  };

  const setFormFieldError = (fieldName, errorMsg) => {
    propertyPreferencesForm.setFields([
      {
        name: fieldName,
        errors: [errorMsg],
      },
    ]);
  };

  const handleFieldsChanges = (changedFields) => {
    const fieldName = changedFields[0].name[0];
    const fieldValue = changedFields[0].value;

    if (fieldName === "NumberOfOccupants") {
      if (fieldValue !== "") {
        const isValidInput =
          RegularExpressions.PositiveNegativeNumbers.test(fieldValue);
        if (isValidInput) {
          if (fieldValue < 0) {
            setFormFieldError(
              "NumberOfOccupants",
              "Number of Occupants cannot be a negative number.",
            );
          } else {
            clearFormFieldError("NumberOfOccupants");
          }
        } else {
          setFormFieldError(
            "NumberOfOccupants",
            "Number of Occupants is invalid.",
          );
        }
      } else {
        clearFormFieldError("NumberOfOccupants");
      }
    }

    if (fieldName === "CostOfWater") {
      if (fieldValue !== "") {
        const isValidInput =
          RegularExpressions.PositiveNegativeNumbersWithDecimal.test(
            fieldValue,
          );
        if (isValidInput) {
          if (fieldValue < 0) {
            setFormFieldError(
              "CostOfWater",
              "Cost of Water cannot be a negative number.",
            );
          } else {
            clearFormFieldError("CostOfWater");
          }
        } else {
          setFormFieldError("CostOfWater", "Cost Of Water is invalid.");
        }
      } else {
        clearFormFieldError("CostOfWater");
      }
    }

    if (fieldName === "WorkingDaysEnabled") {
      if (fieldValue === true) {
        setShowWorkingDaysRoutine(true);
      } else {
        setShowWorkingDaysRoutine(false);
      }
    }
  };

  const handleUpdate = (formValues) => {
    const request = {
      Id: propertyId,
      Name: formValues.Name,
      Address: formValues.Address,
      ImageUrl: formValues.ImageUrl,
      PropertyPreferences: {
        NumberOfOccupants: formValues.NumberOfOccupants,
        CostOfWater: formValues.CostOfWater ?? 0,
        Currency: formValues.Currency ?? "USD",
        WorkingDaysEnabled: formValues.WorkingDaysEnabled,
        WorkingDaysStartDayOfWeek: formValues.WorkingDaysStartDay
          ? formValues.WorkingDaysStartDay
          : initialFormValues.current.WorkingDaysStartDay,
        WorkingDaysEndDayOfWeek: formValues.WorkingDaysEndDay
          ? formValues.WorkingDaysEndDay
          : initialFormValues.current.WorkingDaysEndDay,
        WorkingDaysStartTime: formValues.WorkingDaysStartTime
          ? formValues.WorkingDaysStartTime.format("HH:mm")
          : initialFormValues.current.WorkingDaysStartTime.format("HH:mm"),
        WorkingDaysEndTime: formValues.WorkingDaysEndTime
          ? formValues.WorkingDaysEndTime.format("HH:mm")
          : initialFormValues.current.WorkingDaysEndTime.format("HH:mm"),
      },
    };

    updatePropertyPreferences(request, {
      onSuccess: () => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      },
      onError: (response) => {
        setIsLoading(false);
        notification.error({
          message: (
            <span className="uppercase tracking-widest">
              Something went wrong!
            </span>
          ),
          duration: 5,
          description: response.message,
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });
      },
    });
  };

  const handleUpdateWithImage = (formValues) => {
    const { file } = formValues.ImageUrl;
    const data = new FormData();
    data.append("file", file);
    uploadAttachment(data, {
      onSuccess: (responseUploadAttachment) => {
        const request = {
          Id: propertyId,
          Name: formValues.Name,
          Address: formValues.Address,
          ImageUrl: responseUploadAttachment.data,
          PropertyPreferences: {
            NumberOfOccupants: formValues.NumberOfOccupants,
            CostOfWater: formValues.CostOfWater ?? 0,
            Currency: formValues.Currency ?? "USD",
            WorkingDaysEnabled: formValues.WorkingDaysEnabled,
            WorkingDaysStartDayOfWeek: formValues.WorkingDaysStartDay
              ? formValues.WorkingDaysStartDay
              : initialFormValues.current.WorkingDaysStartDay,
            WorkingDaysEndDayOfWeek: formValues.WorkingDaysEndDay
              ? formValues.WorkingDaysEndDay
              : initialFormValues.current.WorkingDaysEndDay,
            WorkingDaysStartTime: formValues.WorkingDaysStartTime
              ? formValues.WorkingDaysStartTime.format("HH:mm")
              : initialFormValues.current.WorkingDaysStartTime.format("HH:mm"),
            WorkingDaysEndTime: formValues.WorkingDaysEndTime
              ? formValues.WorkingDaysEndTime.format("HH:mm")
              : initialFormValues.current.WorkingDaysEndTime.format("HH:mm"),
          },
        };

        updatePropertyPreferences(request, {
          onSuccess: () => {
            getPropertyPreferences(propertyId).then((response) => {
              propertyPreferencesForm.setFieldValue(
                "ImageUrl",
                response.data.ImageUrl,
              );
            });
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);
          },
        });
      },
      onError: () => {
        setIsLoading(false);
        notification.error({
          message: (
            <span className="uppercase tracking-widest">
              Something went wrong!
            </span>
          ),
          duration: 0,
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });
      },
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const formValues = propertyPreferencesForm.getFieldsValue();
    if (formValues.ImageUrl && typeof formValues.ImageUrl === "object") {
      handleUpdateWithImage(formValues);
    } else {
      handleUpdate(formValues);
    }
  };

  const onRemoveImg = () => {
    setFileListArr([]);
  };

  const onImageChange = (info) => {
    if (info.file.status === "removed") {
      propertyPreferencesForm.setFieldValue("ImageUrl", null);
    }
    setFileListArr(info.fileList);
  };

  return (
    <div className="content-wrapper">
      <Form
        form={propertyPreferencesForm}
        name="update-property-preferences"
        layout="vertical"
        className="w-full"
        initialValues={{
          Name: initialFormValues.current.Name,
          Address: initialFormValues.current.Address,
          NumberOfOccupants: initialFormValues.current?.NumberOfOccupants,
          CostOfWater: initialFormValues.current?.CostOfWater,
          ImageUrl: initialFormValues.current?.ImageUrl,
          Currency: initialFormValues.current?.Currency
            ? initialFormValues.current?.Currency
            : "USD",
          WorkingDaysEnabled: initialFormValues.current?.WorkingDaysEnabled,
          WorkingDaysStartDay: initialFormValues.current?.WorkingDaysStartDay,
          WorkingDaysEndDay: initialFormValues.current?.WorkingDaysEndDay,
          WorkingDaysStartTime: initialFormValues.current?.WorkingDaysStartTime
            ? dayjs(initialFormValues.current?.WorkingDaysStartTime, "HH:mm")
            : "",
          WorkingDaysEndTime: initialFormValues.current?.WorkingDaysEndTime
            ? dayjs(initialFormValues.current?.WorkingDaysEndTime, "HH:mm")
            : "",
        }}
        onFinish={handleSubmit}
        onFieldsChange={handleFieldsChanges}
      >
        <div className="flex items-center justify-between mb-0">
          <h1 className="page-title">Property Preferences</h1>
        </div>
        <>
          <Form.Item name="ImageUrl">
            <ImageUploader
              fileListArr={fileListArr}
              onRemoveImg={onRemoveImg}
              onChange={onImageChange}
              autoUpload={false}
            />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label="Name"
            name="Name"
            rules={[{ required: true, message: "Name field is required." }]}
          >
            <Input size="middle" maxLength={64} />
          </Form.Item>
          <Form.Item className="mb-4" label="Address" name="Address">
            <Input size="middle" maxLength={128} />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label="Number of Occupants"
            name="NumberOfOccupants"
          >
            <Input size="middle" maxLength={10} />
          </Form.Item>
          {/* <Form.Item className="mb-4" label="Cost of Water" name="CostOfWater">
            <Input size="large" maxLength={10} />
          </Form.Item>
          <Form.Item className="mb-4" label="Currency" name="Currency">
            <Select
              placeholder="Currency"
              options={CurrencyOptions}
              size="large"
            />
          </Form.Item> */}
          <Toggle
            className="mb-4"
            size="medium"
            fieldLabel="Working Days Routine"
            fieldName="WorkingDaysEnabled"
            fieldValues={[
              {
                value: false,
                label: "Disable",
              },
              {
                value: true,
                label: "Enable",
              },
            ]}
          />

          {showWorkingDaysRoutine === true ? (
            <>
              <p className="mb-2">Working Days Routine Setup</p>
              <div className="inline-flex w-full">
                <Form.Item name="WorkingDaysStartDay" className="w-full">
                  <Select
                    placeholder="Start Day"
                    className="working-days-picker"
                    options={Object.values(
                      DateConstants.fullyQualifiedDaysOfWeek,
                    ).map((day, i) => {
                      return { value: i, label: day };
                    })}
                  />
                </Form.Item>
                <span className="divider-To m-2">To</span>
                <Form.Item name="WorkingDaysEndDay" className="w-full">
                  <Select
                    placeholder="End Day"
                    className="working-days-picker"
                    options={Object.values(
                      DateConstants.fullyQualifiedDaysOfWeek,
                    ).map((day, i) => {
                      return { value: i, label: day };
                    })}
                  />
                </Form.Item>
              </div>
              <div className="inline-flex w-full">
                <Form.Item name="WorkingDaysStartTime" className="w-full">
                  <TimePicker
                    className="working-days-timepicker w-full"
                    format="HH:mm"
                    inputReadOnly
                    placeholder="Start Time"
                    showNow={false}
                  />
                </Form.Item>
                <span className="divider-To m-2">To</span>
                <Form.Item name="WorkingDaysEndTime" className="w-full">
                  <TimePicker
                    className="working-days-timepicker w-full"
                    format="HH:mm"
                    inputReadOnly
                    placeholder="End Time"
                    size="middle"
                    showNow={false}
                  />
                </Form.Item>
              </div>
            </>
          ) : null}
          <div className="flex justify-center items-center">
            <SaveChanges
              className="mb-0"
              size="small"
              text="Save"
              isLoading={isLoading}
              disableSaveChanges={disableSaveChanges}
            />
          </div>
        </>
      </Form>
    </div>
  );
}

PropertyPreferences.propTypes = {
  propertyId: PropTypes.number,
};

PropertyPreferences.defaultProps = {
  propertyId: null,
};

export default PropertyPreferences;
