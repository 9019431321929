import axios from "../axios";

export const authenticateUser = async (username, password) => {
  const loggedUser = await axios({
    method: "POST",
    url: "/Token/v4",
    data: { username, password },
  }).then((res) => res.data);

  return loggedUser;
};

export const refreshAccessToken = async (refreshToken) => {
  const loggedUser = await axios({
    method: "POST",
    url: "/Token/v4",
    data: { grant_type: "refresh_token", refresh_token: refreshToken },
  }).then((res) => res.data);

  return loggedUser;
};
