import { Button } from "antd";
import PropTypes from "prop-types";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  getMaxWaterConsumptionValue,
  roundToNearest,
} from "../../../helpers/calculateChartYAxisUpperBound";
import useProfileSettings from "../../../hooks/useProfileSettings";
import StatisticsCustomTooltip from "./StatisticsCustomTooltip";
import YAxisLabel from "./StatisticsYAxisLabel";

function StatisticsMonthlyChart({
  waterConsumptionData,
  previousWaterConsumptionData,
  handleHideMonthlyChart,
  showPreviousWaterConsumptionData,
  setDate,
  date,
}) {
  const { userSettings } = useProfileSettings();

  const chartData = showPreviousWaterConsumptionData
    ? waterConsumptionData.StatisticsData.map((item, index) => ({
        Key: item.Day,
        PreviousValue:
          previousWaterConsumptionData?.StatisticsData &&
          previousWaterConsumptionData?.StatisticsData.length > 0 &&
          previousWaterConsumptionData.StatisticsData[index]
            ? previousWaterConsumptionData.StatisticsData[index]
                .WaterConsumption
            : 0,
        Value: item.WaterConsumption,
        Checks: item.Check,
        Alerts: item.Alert,
        Offline: item.Offline,
        PreviousYearChecks:
          previousWaterConsumptionData?.StatisticsData &&
          previousWaterConsumptionData?.StatisticsData.length > 0 &&
          previousWaterConsumptionData.StatisticsData[index]
            ? previousWaterConsumptionData.StatisticsData[index].Check
            : 0,
        PreviousYearAlerts:
          previousWaterConsumptionData?.StatisticsData &&
          previousWaterConsumptionData?.StatisticsData.length > 0 &&
          previousWaterConsumptionData.StatisticsData[index]
            ? previousWaterConsumptionData.StatisticsData[index].Alert
            : 0,
        PreviousYearOffline:
          previousWaterConsumptionData?.StatisticsData &&
          previousWaterConsumptionData?.StatisticsData.length > 0 &&
          previousWaterConsumptionData.StatisticsData[index]
            ? previousWaterConsumptionData.StatisticsData[index].Offline
            : 0,
      }))
    : waterConsumptionData?.StatisticsData.map((item) => ({
        Key: item.Day,
        Value: item.WaterConsumption,
        Checks: item.Check,
        Alerts: item.Alert,
        Offline: item.Offline,
      }));

  const maxWaterConsumption = getMaxWaterConsumptionValue(
    waterConsumptionData?.StatisticsData,
  );

  let maxYAxisValue = maxWaterConsumption;

  if (showPreviousWaterConsumptionData) {
    const maxPreviousWaterConsumption = getMaxWaterConsumptionValue(
      previousWaterConsumptionData?.StatisticsData,
    );

    maxYAxisValue = Math.max(maxWaterConsumption, maxPreviousWaterConsumption);
  }

  const handleBarClick = (selectedItem) => {
    setDate({
      MM: date.MM,
      m: date.m,
      d: selectedItem.Key,
      year: date.year,
    });
  };
  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      className="bg-triple-white rounded-b-xl pl-1"
    >
      <BarChart data={chartData}>
        <Button type="primary" size="small" onClick={handleHideMonthlyChart}>
          close
        </Button>
        <CartesianGrid strokeDasharray="1 0" vertical={false} />
        <XAxis dataKey="Key">
          <Label
            content={
              <g>
                <text x="22" y="269">
                  Day
                </text>
              </g>
            }
          />
        </XAxis>
        <YAxis name="Consumption" domain={[0, roundToNearest(maxYAxisValue)]}>
          <Label content={YAxisLabel(userSettings.waterUnit)} />
        </YAxis>
        <Tooltip
          labelClassName="text-triple-sidebar"
          content={
            <StatisticsCustomTooltip
              year={waterConsumptionData?.Year}
              payload={waterConsumptionData?.StatisticsData}
              active
              chartType="Monthly"
              waterUnit={userSettings.waterUnit}
            />
          }
        />

        <Legend verticalAlign="top" align="right" height={40} />
        {showPreviousWaterConsumptionData && (
          <Bar
            dataKey="PreviousValue"
            name={previousWaterConsumptionData.Year}
            fill="#5CD6EC"
            barSize={15}
            minPointSize={5}
            style={{ cursor: "pointer" }}
          />
        )}
        <Bar
          dataKey="Value"
          name={waterConsumptionData?.Year}
          fill="#03a5d2"
          barSize={15}
          minPointSize={5}
          style={{ cursor: "pointer" }}
          onClick={handleBarClick}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

StatisticsMonthlyChart.defaultProps = {
  waterConsumptionData: {},
  previousWaterConsumptionData: {},
  handleHideMonthlyChart: () => {},
  showPreviousWaterConsumptionData: true,
  setDate: () => {},
  date: {},
};

StatisticsMonthlyChart.propTypes = {
  waterConsumptionData: PropTypes.object,
  previousWaterConsumptionData: PropTypes.object,
  handleHideMonthlyChart: PropTypes.func,
  showPreviousWaterConsumptionData: PropTypes.bool,
  setDate: PropTypes.func,
  date: PropTypes.object,
};

export default StatisticsMonthlyChart;
