import { Button, Space, Form } from "antd";
import PropTypes from "prop-types";

import "./ReportsConsumptionCards.scss";

function ReportsConsumptionCards({
  onExportClick,
  disableGenerateReportButton,
  disableExportReportButton,
  reportLoading,
}) {
  return (
    <Space direction="horizontal" wrap>
      <Form.Item>
        <Button
          htmlType="submit"
          className="generate-report-button"
          type="primary"
          loading={reportLoading}
          disabled={disableGenerateReportButton}
        >
          Generate Report
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          className="export-button"
          type="primary"
          onClick={onExportClick}
          loading={reportLoading}
          disabled={disableExportReportButton}
        >
          Export as CSV
        </Button>
      </Form.Item>
    </Space>
  );
}

ReportsConsumptionCards.defaultProps = {
  onExportClick: () => {},
  disableGenerateReportButton: true,
  disableExportReportButton: true,
  reportLoading: false,
};

ReportsConsumptionCards.propTypes = {
  onExportClick: PropTypes.func,
  disableGenerateReportButton: PropTypes.bool,
  disableExportReportButton: PropTypes.bool,
  reportLoading: PropTypes.bool,
};

export default ReportsConsumptionCards;
