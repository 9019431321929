import { Table } from "antd";
import PropTypes from "prop-types";

const propTypes = {
  dataAsStringArr: PropTypes.array,
};

const defaultProps = {
  dataAsStringArr: [],
};

function ReportsTable({ dataAsStringArr }) {
  if (dataAsStringArr && dataAsStringArr.length > 0) {
    // Table column headers
    const headerKeys =
      dataAsStringArr && dataAsStringArr.length > 0
        ? Object.keys(dataAsStringArr[0])
        : [];
    const headers = headerKeys.map((h) => ({
      title: h,
      dataIndex: h,
      key: h,
      width: 200,
    }));

    // Actual table data
    const mappedDataToHeader = dataAsStringArr.map((row) =>
      headerKeys.map((header) => {
        return {
          [header]: row[header],
        };
      }),
    );
    const refinedDataForTable = mappedDataToHeader.map((result, key) => {
      return Object.assign({}, ...result, {
        key,
      });
    });

    return (
      <Table
        columns={headers}
        dataSource={refinedDataForTable}
        scroll={{
          x: 1000,
          y: 500,
        }}
      />
    );
  }

  return (
    <Table
      columns={[]}
      dataSource={[]}
      scroll={{
        x: 500,
      }}
    />
  );
}

ReportsTable.propTypes = propTypes;
ReportsTable.defaultProps = defaultProps;

export default ReportsTable;
