import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useStatisticsBiennialWaterConsumptionV2(
  year,
  params,
  options,
  key = "getBiennialWaterConsumptionData",
) {
  const axiosPrivate = useAxiosPrivate();
  const getBiennialWaterConsumptionDataV2 = async () => {
    const { data } = await axiosPrivate({
      method: "GET",
      url: `Statistics/WaterConsumption/BiennialV2/${year}`,
      params,
    });

    return data;
  };

  return useQuery(
    [key],
    () => getBiennialWaterConsumptionDataV2(year, params),
    {
      ...options,
    },
  );
}

export default useStatisticsBiennialWaterConsumptionV2;
