import { useEffect, useState } from "react";

import {
  Select,
  Space,
  Row,
  Col,
  Input,
  DatePicker,
  Switch,
  Divider,
  Form,
} from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import useWaterConsuptionFilters from "../../../api/hooks/useWaterConsuptionFilters";
import "./ReportsFilters.scss";
import DateConstants from "../../../constants/DateConstants";

const dateFormatsForBucketTypeYear = [
  {
    value: DateConstants.formats.fullYear_monthAbbreviation,
    label: DateConstants.formats.fullYear_monthAbbreviation_label,
  },
  {
    value: DateConstants.formats.fullYear_fullMonthName,
    label: DateConstants.formats.fullYear_fullMonthName_label,
  },
  {
    value: DateConstants.formats.monthAbbreviation_fullYear,
    label: DateConstants.formats.monthAbbreviation_fullYear_label,
  },
  {
    value: DateConstants.formats.fullMonthName_fullYear,
    label: DateConstants.formats.fullMonthName_fullYear_label,
  },
];

const dateFormatsForBucketTypeMonth = [
  {
    value: DateConstants.formats.twoDigitsYear_monthAbbreviation_dayOfMonth,
    label:
      DateConstants.formats.twoDigitsYear_monthAbbreviation_dayOfMonth_label,
  },
  {
    value: DateConstants.formats.fullYear_monthAbbreviation_dayOfMonth,
    label: DateConstants.formats.fullYear_monthAbbreviation_dayOfMonth_label,
  },
];

const dateFormatsForBucketTypeDay = [
  {
    value:
      DateConstants.formats.dayOfMonth_monthAbbreviation_fullYear_hours_minutes,
    label:
      DateConstants.formats
        .dayOfMonth_monthAbbreviation_fullYear_hours_minutes_label,
  },
  {
    value:
      DateConstants.formats
        .dayOfWeekName_dayOfMonth_monthAbbreviation_fullYear_hours_minutes,
    label:
      DateConstants.formats
        .dayOfWeekName_dayOfMonth_monthAbbreviation_fullYear_hours_minutes_label,
  },
];

function ReportsFilters({ handleOnChangeFilterCallback, generateReportForm }) {
  const [floorSelectDisabled, setFloorSelectDisabled] = useState(true);
  const [systemSelectDisabled, setSystemSelectDisabled] = useState(true);
  // comment below applies to the following three states: selectedPropertyId, selectedFloorId & selectedSystemId
  // using "" as default value, rather than null, due to errors in browser console when select options' value is null
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedFloorId, setSelectedFloorId] = useState("");
  const [selectedSystemId, setSelectedSystemId] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedDateFormat, setSelectedDateFormat] = useState(
    DateConstants.formats.fullYear_monthAbbreviation,
  );
  const [selectedAggregationLevel, setSelectedAggregationLevel] =
    useState("Device");
  const [selectedBucketType, setSelectedBucketType] = useState("year");
  const [selectedIncludePreviousYear, setSelectedIncludePreviousYear] =
    useState(false);
  const [selectedIncludeTotal, setSelectedIncludeTotal] = useState(true);
  const [selectedUnitOfMeasurement, setSelectedUnitOfMeasurement] =
    useState("liters");
  const [propertyNameField, setPropertyNameField] = useState("Property");
  const [propertyAddressField, setPropertyAddressField] =
    useState("Property Address");
  const [floorNameField, setFloorNameField] = useState("Floor");
  const [systemNameField, setSystemNameField] = useState("System");
  const [deviceNameField, setDeviceNameField] = useState("Device");
  const [deviceSerialNumberField, setDeviceSerialNumberField] = useState(
    "Device Serial Number",
  );
  const [deviceLocationField, setDeviceLocationField] =
    useState("Device Location");
  const [deviceTypeField, setDeviceTypeField] = useState("Device Type");
  const [datePickerOption, setDatePickerOption] = useState("year");
  const [dateFormatOptions, setDateFormatOptions] = useState(
    dateFormatsForBucketTypeYear,
  );
  const [floorOptions, setFloorOptions] = useState([
    { value: "", label: "All Floors" },
  ]);
  const [systemOptions, setSystemOptions] = useState([
    { value: "", label: "All Systems" },
  ]);
  const propertyOptions = [{ value: "", label: "All Properties" }];
  const aggregationLevelOptions = [
    { value: "Device", label: "Device" },
    { value: "System", label: "System" },
    { value: "Floor", label: "Floor" },
    { value: "Property", label: "Property" },
  ];
  const bucketTypeOptions = [
    { value: "year", label: "Year" },
    { value: "month", label: "Month" },
    { value: "day", label: "Day" },
  ];
  const unitOfMeasurementOptions = [{ value: "liters", label: "Liters" }];

  // Hooks
  const { data: propertiesObject } = useWaterConsuptionFilters();
  // Configure the select dropdowns with values
  if (propertiesObject && propertiesObject.Properties) {
    propertiesObject.Properties.forEach((p) =>
      propertyOptions.push({ value: p.Id, label: p.Name }),
    );
  }

  // Effects
  useEffect(() => {
    if (
      selectedPropertyId !== "" &&
      propertiesObject &&
      propertiesObject.Properties
    ) {
      let property = null;
      const tempFloorOptions = [{ value: "", label: "All Floors" }];
      const tempSystemOptions = [{ value: "", label: "All Systems" }];
      propertiesObject.Properties.forEach((p) => {
        if (p.Id === selectedPropertyId) {
          property = p;
        }
      });

      if (property) {
        property.Floors?.forEach((f) => {
          tempFloorOptions.push({ value: f.Id, label: f.Name });

          if (f) {
            f?.Systems?.forEach((s) =>
              tempSystemOptions.push({ value: s.Id, label: s.Name }),
            );
          } else if (selectedFloorId === "") {
            property?.Floors?.forEach((floor) => {
              floor.Systems.map((fs) =>
                tempSystemOptions.push({ value: fs.Id, label: fs.Name }),
              );
            });
          }
        });
      }

      setFloorOptions(tempFloorOptions);
      setSystemOptions(tempSystemOptions);
    }
  }, [selectedPropertyId, selectedFloorId, propertiesObject]);

  useEffect(() => {
    handleOnChangeFilterCallback(
      selectedPropertyId,
      selectedFloorId,
      selectedSystemId,
    );
  });

  // Effects
  useEffect(() => {
    handleOnChangeFilterCallback(
      selectedPropertyId,
      selectedFloorId,
      selectedSystemId,
      selectedDate,
      selectedDateFormat,
      selectedAggregationLevel,
      selectedBucketType,
      selectedIncludePreviousYear,
      selectedIncludeTotal,
      propertyNameField,
      propertyAddressField,
      floorNameField,
      systemNameField,
      deviceNameField,
      deviceSerialNumberField,
      deviceLocationField,
      deviceTypeField,
    );
  });

  const onChangeProperty = (value) => {
    if (!value) {
      setSelectedPropertyId("");
      setFloorSelectDisabled(true);
      setSystemSelectDisabled(true);
    } else {
      setSelectedPropertyId(value);
      setFloorSelectDisabled(false);
      setSystemSelectDisabled(false);
    }

    // on changing property filter option
    // set floor & system filter options to default
    setSelectedFloorId(""); // using "" as default value, rather than null, due to errors in browser console when select options' value is null
    setSelectedSystemId("");
  };

  const onChangeFloor = (value) => {
    if (value) {
      setSelectedFloorId(value);
    }

    // on changing floor filter option
    // set system filter options to default
    setSelectedSystemId(""); // using "" as default value, rather than null, due to errors in browser console when select options' value is null
  };

  const onChangeSystem = (value) => {
    if (value) {
      setSelectedSystemId(value);
    }
  };

  const onDateChange = (_, dateString) => {
    if (dateString) {
      setSelectedDate(dateString);
    }
  };

  const onChangeDateFormat = (value) => {
    if (value) {
      setSelectedDateFormat(value);
    } else {
      setSelectedDateFormat(DateConstants.formats.fullYear_monthAbbreviation);
    }
  };

  const onChangeAggregationLevel = (value) => {
    if (value) {
      setSelectedAggregationLevel(value);
    } else {
      setSelectedAggregationLevel("Device");
    }
  };

  const onChangeBucketType = (value) => {
    if (value) {
      const previousBucketType = selectedBucketType;
      const goingLowerBucketType =
        (previousBucketType === "year" &&
          (value === "month" || value === "day")) ||
        (previousBucketType === "month" && value === "day"); // (year => month => day)
      setDatePickerOption(value);
      setSelectedBucketType(value);

      if (goingLowerBucketType) {
        // we want to update the date (timeframe selection selector) state
        // the logic is as follows:
        // 1. if current bucket type selection is year:
        //    a. if selectedDate is null - we set today's year
        //    b. if selectedDate has value - we set only the value's year
        // 2. if current bucket type selection is month:
        //    a. if selectedDate is null - we set today's year + today's month
        //    b. if selectedDate has value - we set the value's year + the value's month
        // 3. if current bucket type selection is day:
        //    a. if selectedDate is null - we set today's date
        //    b. if selectedDate has value - we set the value's year + the value's month + the value's day
        const newDate = `${new Date(
          selectedDate === null ? dayjs() : selectedDate,
        ).getFullYear()}-${dayjs().month() + 1}-${dayjs().date()}`; // ${dayjs().month() + 1} is not ideal, but javascript-based dayjs() library month values are zero indexed

        setSelectedDate(dayjs(newDate));

        // after updating the selectedDate' state
        // we want to update the actual date picker field in the UI
        generateReportForm.setFieldsValue({
          date: goingLowerBucketType ? dayjs(newDate) : dayjs(selectedDate),
        });
      }
      // the above logic is not necessary for switching to a higher level (day => week => month => year)

      // set date format options according to selected timeframe
      if (value === "day") {
        setDateFormatOptions(dateFormatsForBucketTypeDay);
        setSelectedDateFormat(
          DateConstants.formats
            .dayOfMonth_monthAbbreviation_fullYear_hours_minutes,
        );

        // after updating the selectedDateFormat's state
        // we want to update the actual selector field in the UI
        generateReportForm.setFieldsValue({
          dateFormat:
            DateConstants.formats
              .dayOfMonth_monthAbbreviation_fullYear_hours_minutes,
        });
      } else if (value === "month") {
        setDateFormatOptions(dateFormatsForBucketTypeMonth);
        setSelectedDateFormat(
          DateConstants.formats.twoDigitsYear_monthAbbreviation_dayOfMonth,
        );

        generateReportForm.setFieldsValue({
          dateFormat:
            DateConstants.formats.twoDigitsYear_monthAbbreviation_dayOfMonth,
        });
      } else {
        setDateFormatOptions(dateFormatsForBucketTypeYear);
        setSelectedDateFormat(DateConstants.formats.fullYear_monthAbbreviation);

        generateReportForm.setFieldsValue({
          dateFormat: DateConstants.formats.fullYear_monthAbbreviation,
        });
      }
    } else {
      setSelectedBucketType("year");
      setDatePickerOption("year");

      setDateFormatOptions(dateFormatsForBucketTypeYear);
      setSelectedDateFormat(DateConstants.formats.fullYear_monthAbbreviation);

      generateReportForm.setFieldsValue({
        dateFormat: DateConstants.formats.fullYear_monthAbbreviation,
      });
    }
  };

  const onChangeIncludePreviousYear = (value) => {
    setSelectedIncludePreviousYear(value);
  };

  const onChangeIncludeTotal = (value) => {
    setSelectedIncludeTotal(value);
  };

  const onChangeUnitOfMeasurement = (value) => {
    setSelectedUnitOfMeasurement(value);
  };

  const filterSelect = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="reports-filters">
      <Divider orientation="left" orientationMargin={0}>
        Choose report filters
      </Divider>
      <Space direction="horizontal" wrap>
        <Select
          dropdownStyle={{ minWidth: "130px" }}
          showSearch
          placeholder="All Properties"
          optionFilterProp="property"
          onChange={onChangeProperty}
          filterOption={filterSelect}
          className="property-selector"
          defaultValue=""
          options={propertyOptions}
        />
        <Select
          dropdownStyle={{ minWidth: "130px" }}
          showSearch
          placeholder="All Floors"
          optionFilterProp="propertyFloor"
          onChange={onChangeFloor}
          filterOption={filterSelect}
          className="floor-selector"
          disabled={floorSelectDisabled}
          value={selectedFloorId}
          options={floorOptions}
        />
        <Select
          showSearch
          dropdownStyle={{ minWidth: "130px" }}
          placeholder="All Systems"
          optionFilterProp="propertySystem"
          onChange={onChangeSystem}
          filterOption={filterSelect}
          className="system-selector"
          disabled={systemSelectDisabled}
          value={selectedSystemId}
          options={systemOptions}
        />
      </Space>
      <Divider orientation="left" orientationMargin={0}>
        Choose proper timeframe, aggregation level & date format
      </Divider>
      <Space className="-my-6" direction="horizontal" wrap>
        <Form.Item
          label="Aggregation Level"
          name="aggregationLevel"
          rules={[
            {
              required: true,
              message: "Aggregation Level field is required.",
            },
          ]}
        >
          <Select
            dropdownStyle={{ minWidth: "130px" }}
            placeholder="Aggregation Level"
            onChange={onChangeAggregationLevel}
            filterOption={filterSelect}
            className="aggregation-level-selector"
            value={selectedAggregationLevel}
            options={aggregationLevelOptions}
          />
        </Form.Item>
        <Form.Item
          label="Timeframe"
          name="bucketType"
          rules={[
            { required: true, message: "Bucket Type field is required." },
          ]}
        >
          <Select
            dropdownStyle={{ minWidth: "130px" }}
            placeholder="Timeframe"
            onChange={onChangeBucketType}
            filterOption={filterSelect}
            className="bucket-type-selector"
            value={selectedBucketType}
            options={bucketTypeOptions}
          />
        </Form.Item>
        <Form.Item
          label="Timeframe Selection"
          name="date"
          rules={[{ required: true, message: "Date field is required." }]}
        >
          <DatePicker
            className="report-filter-datepicker"
            inputReadOnly
            placeholder="Select specific timeframe"
            value={selectedDate}
            onChange={(_, dateString) =>
              onDateChange(_, dateString, "ReportDate")
            }
            disabledDate={(current) => {
              if (current.isAfter(dayjs())) {
                return true;
              }

              return false;
            }}
            picker={datePickerOption}
          />
        </Form.Item>
        <Form.Item
          label="Date Format"
          name="dateFormat"
          rules={[
            { required: true, message: "Date Format field is required." },
          ]}
        >
          <Select
            dropdownStyle={{ minWidth: "28rem" }}
            placeholder="Date Format"
            onChange={onChangeDateFormat}
            filterOption={filterSelect}
            className="date-format-selector"
            value={selectedDateFormat}
            options={dateFormatOptions}
          />
        </Form.Item>
      </Space>
      <Divider orientation="left" orientationMargin={0}>
        Choose report settings
      </Divider>
      <Space className="-my-6" direction="horizontal" wrap>
        <Form.Item
          label="Unit of Measurement"
          name="unitOfMeasurement"
          rules={[
            {
              required: true,
              message: "Unit of Measurement field is required.",
            },
          ]}
        >
          <Select
            dropdownStyle={{ minWidth: "130px" }}
            placeholder="Unit of Measurement"
            onChange={onChangeUnitOfMeasurement}
            filterOption={filterSelect}
            className="unit-of-measurement-selector"
            value={selectedUnitOfMeasurement}
            options={unitOfMeasurementOptions}
          />
        </Form.Item>
        <Form.Item
          label="Include Previous Year"
          name="includePreviousYear"
          valuePropName="checked"
          className="ml-6"
        >
          <Switch
            className="include-previous-year-switch"
            defaultChecked={selectedIncludePreviousYear}
            onChange={onChangeIncludePreviousYear}
          />
        </Form.Item>
        <Form.Item
          label="Include Total"
          name="includeTotal"
          valuePropName="checked"
          className="ml-6"
        >
          <Switch
            className="include-total-switch"
            defaultChecked={selectedIncludeTotal}
            onChange={onChangeIncludeTotal}
          />
        </Form.Item>
      </Space>
      <Divider orientation="left" orientationMargin={0}>
        Add custom field namings
      </Divider>
      <Row gutter={8} className="mb-2">
        {(selectedAggregationLevel === "Device" ||
          selectedAggregationLevel === "System" ||
          selectedAggregationLevel === "Floor" ||
          selectedAggregationLevel === "Property") && (
          <>
            <Col className="gutter-row" span={4}>
              <Input
                placeholder="PropertyName"
                allowClear
                value={propertyNameField}
                onChange={(e) => setPropertyNameField(e.target.value)}
              />
            </Col>
            <Col className="gutter-row" span={4}>
              <Input
                placeholder="PropertyAddress"
                allowClear
                value={propertyAddressField}
                onChange={(e) => setPropertyAddressField(e.target.value)}
              />
            </Col>
          </>
        )}
        {(selectedAggregationLevel === "Device" ||
          selectedAggregationLevel === "System" ||
          selectedAggregationLevel === "Floor") && (
          <Col className="gutter-row" span={4}>
            <Input
              placeholder="FloorName"
              allowClear
              value={floorNameField}
              onChange={(e) => setFloorNameField(e.target.value)}
            />
          </Col>
        )}
        {(selectedAggregationLevel === "Device" ||
          selectedAggregationLevel === "System") && (
          <Col className="gutter-row" span={4}>
            <Input
              placeholder="SystemName"
              allowClear
              value={systemNameField}
              onChange={(e) => setSystemNameField(e.target.value)}
            />
          </Col>
        )}
      </Row>
      <Row gutter={8} className="mb-4">
        {selectedAggregationLevel === "Device" && (
          <>
            <Col className="gutter-row" span={4}>
              <Input
                placeholder="DeviceName"
                allowClear
                value={deviceNameField}
                onChange={(e) => setDeviceNameField(e.target.value)}
              />
            </Col>
            <Col className="gutter-row" span={4}>
              <Input
                placeholder="DeviceSerialNumber"
                allowClear
                value={deviceSerialNumberField}
                onChange={(e) => setDeviceSerialNumberField(e.target.value)}
              />
            </Col>
            <Col className="gutter-row" span={4}>
              <Input
                placeholder="DeviceLocation"
                allowClear
                value={deviceLocationField}
                onChange={(e) => setDeviceLocationField(e.target.value)}
              />
            </Col>
            <Col className="gutter-row" span={4}>
              <Input
                placeholder="DeviceType"
                allowClear
                value={deviceTypeField}
                onChange={(e) => setDeviceTypeField(e.target.value)}
              />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}

ReportsFilters.defaultProps = {
  handleOnChangeFilterCallback: () => {},
  generateReportForm: {},
};
ReportsFilters.propTypes = {
  handleOnChangeFilterCallback: PropTypes.func,
  generateReportForm: PropTypes.object,
};

export default ReportsFilters;
